/* CSS */
import "@/js/components/css"

/* JS */
import "@/js/components/lazyloading"
import "@/js/components/superscript"

import imageSlider from "@/js/components/imageSlider.js"
import cardSlider from "@/js/components/cardSlider.js"
import swiperMobile from "@/js/components/swiperMobile.js"
import observer from "@/js/components/observer.js"
import circlesAnimation from "@/js/components/circlesAnimation.js"
import rellax from "@/js/components/rellax.js"

/**
 * Alpine JS
 * Best to put it last so that all other potential JS is available
 * when components start getting initialized.
 */
import Alpine from "alpinejs"

window.Alpine = Alpine

Alpine.data("imageSlider", imageSlider)
Alpine.data("cardSlider", cardSlider)
Alpine.data("swiperMobile", swiperMobile)
Alpine.data("observer", observer)
Alpine.data("circlesAnimation", circlesAnimation)
Alpine.data("rellax", rellax)

Alpine.start()

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR")
  })
}
