import Swiper from "swiper/bundle"
import "swiper/swiper.css"
import "swiper/css/effect-fade"
import observer from "../utils/observer"

export default () => ({
  isInView: false,
  init() {
    const ctx = this

    observer(ctx, ctx.$refs.observer, 0)

    const swiperThumbs = new Swiper(ctx.$refs.thumbsContainer, {
      spaceBetween: 16,
      slidesPerView: 4,
      freeMode: true,
      watchSlidesProgress: true,
    })

    const swiper = new Swiper(ctx.$refs.container, {
      loop: true,
      slidesPerView: 1,
      spaceBetween: 16,
      effect: "fade",
      speed: 500,
      fadeEffect: {
        crossFade: true,
      },
      navigation: {
        nextEl: ctx.$refs.buttonNext,
        prevEl: ctx.$refs.buttonPrev,
      },
      thumbs: {
        swiper: swiperThumbs,
      },
      pagination: {
        el: ctx.$refs.pagination,
        type: "custom",
        renderCustom: function (swiper, current, total) {
          return (
            "<div>" +
            0 +
            current +
            "</div>" +
            "—" +
            "<div>" +
            0 +
            total +
            "</div>"
          )
        },
      },
    })
  },
})
