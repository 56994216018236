import prefersReducedMotion from "./prefersReducedMotion"

export default function observer(
  ctx,
  target,
  delay = 0,
  rootMarginBottom = "-25%",
) {
  if (prefersReducedMotion()) {
    ctx.isInView = true
    return
  }
  let observer = new IntersectionObserver(
    entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          ctx.isInView = true
          observer.disconnect()
        }
      })
    },
    {
      threshold: 0.1,
      rootMargin: `0% 0% ${rootMarginBottom} 0%`,
      root: null,
    },
  )

  setTimeout(() => {
    observer.observe(target)
  }, delay)
}
