import Rellax from "rellax"
import prefersReducedMotion from "../utils/prefersReducedMotion"

export default (speed = 2, center = true) => ({
  init() {
    if (prefersReducedMotion()) {
      return
    }

    const breakpoint = window.matchMedia("(min-width: 1024px)")
    const rellaxSpeed = breakpoint.matches === false ? speed / 2 : speed
    const rellaxCenter = breakpoint.matches === false ? true : center

    this.$nextTick(() => {
      new Rellax(this.$el, {
        speed: rellaxSpeed,
        center: rellaxCenter,
        wrapper: null,
        round: false,
        vertical: true,
        horizontal: false,
      })
    })
  },
})
