import Swiper from "swiper/bundle"
import "swiper/swiper.css"
import "swiper/css/effect-fade"
import observer from "../utils/observer"
import gsap from "gsap"

export default images => ({
  currentTitle: "",
  activeIndex: 0,
  thumbs: [],
  images: images,
  totalSlides: images?.length,
  slideIsChanging: false,
  isInView: false,
  init() {
    const ctx = this

    observer(ctx, ctx.$refs.container, 0)

    const swiper = new Swiper(ctx.$refs.container, {
      loop: true,
      slidesPerView: 1,
      spaceBetween: 16,
      effect: "fade",
      speed: 500,
      navigation: {
        nextEl: ctx.$refs.buttonNext,
        prevEl: ctx.$refs.buttonPrev,
      },
      pagination: {
        el: ctx.$refs.pagination,
        type: "custom",
        renderCustom: function (swiper, current, total) {
          return (
            "<div>" +
            0 +
            current +
            "</div>" +
            "—" +
            "<div>" +
            0 +
            total +
            "</div>"
          )
        },
      },
    })

    this.$watch("isInView", value => {
      const thumbs = document.querySelectorAll(".js-thumb")
      gsap.set(thumbs, { autoAlpha: 0 })

      setTimeout(() => {
        gsap.to(thumbs, {
          autoAlpha: 1,
          duration: 1.5,
          ease: "circ",
          stagger: {
            amount: 0.5,
          },
        })
      }, 750)
    })

    const getNextThreeThumbs = activeIndex => {
      ctx.slideIsChanging = true
      setTimeout(() => {
        ctx.slideIsChanging = false
      }, 750)

      let nextIndexes = []

      for (let i = 1; i <= 3; i++) {
        let nextIndex = (activeIndex + i) % ctx.totalSlides
        nextIndexes.push(nextIndex)
      }

      return nextIndexes
    }

    ctx.thumbs = getNextThreeThumbs(ctx.activeIndex)
    ctx.currentTitle = images[ctx.activeIndex].caption

    swiper.on("slideChange", function () {
      ctx.activeIndex = swiper.realIndex

      ctx.thumbs = getNextThreeThumbs(ctx.activeIndex)
      ctx.currentTitle = images[ctx.activeIndex].caption
    })
  },
})
