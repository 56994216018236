import Swiper from "swiper/bundle"
import "swiper/css"

export default (breakpointSize = "1024px", spaceBetween = 16) => ({
  slidePrev() {
    this.$refs.container.swiper.slidePrev()
  },

  slideNext() {
    this.$refs.container.swiper.slideNext()
  },

  init() {
    const ctx = this
    const breakpoint = window.matchMedia(`(min-width:${breakpointSize})`)

    let mobileSwiper

    const checkBreakpoint = function () {
      // if larger viewport and no slider needed
      if (breakpoint.matches === true) {
        // clean up old instances and inline styles when available
        if (mobileSwiper !== undefined) mobileSwiper.destroy(true, true)
        // or/and do nothing
        return
        // else if a small viewport and single column layout needed
      } else if (breakpoint.matches === false) {
        // fire mobile swiper
        return enableSwiper()
      }
    }

    const enableSwiper = function () {
      mobileSwiper = new Swiper(ctx.$refs.container, {
        loop: true,
        slidesPerView: 1,
        spaceBetween: spaceBetween,
        navigation: {
          nextEl: ctx.$refs.buttonNext,
          prevEl: ctx.$refs.buttonPrev,
        },
        pagination: {
          el: ctx.$refs.pagination,
          type: "custom",
          renderCustom: function (swiper, current, total) {
            return (
              "<div>" +
              0 +
              current +
              "</div>" +
              "—" +
              "<div>" +
              0 +
              total +
              "</div>"
            )
          },
        },
      })
    }

    // watch viewport size changes
    breakpoint.addEventListener("change", checkBreakpoint)

    // initiate
    checkBreakpoint()
  },
})
