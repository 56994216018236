import observer from "../utils/observer"
import { gsap } from "gsap"

export default (delay = 0) => ({
  isInView: false,
  init() {
    const target = this.$el
    const paths = this.$el.querySelectorAll("path")

    observer(this, target, delay)

    gsap.set(paths, { autoAlpha: 0, scale: 0.9 })

    this.$watch("isInView", value => {
      setTimeout(() => {
        gsap.to(paths, {
          autoAlpha: 1,
          scale: 1,
          duration: 0.5,
          ease: "circ",
          stagger: {
            amount: 0.25,
          },
        })
        gsap.to(paths, {
          autoAlpha: 0.1,
          scale: 1,
          duration: 0.25,
          delay: 0.5,
          ease: "circ",
          stagger: {
            amount: 0.25,
          },
        })
        gsap.to(paths, {
          autoAlpha: 1,
          scale: 1,
          duration: 0.25,
          delay: 0.75,
          ease: "circ",
          stagger: {
            amount: 0.25,
          },
        })
      }, 1000)
    })
  },
})
