import observer from "../utils/observer"

export default (delay = 0, rootMarginBottom = "-25%") => ({
  isInView: false,
  init() {
    const target = this.$el

    observer(this, target, delay, rootMarginBottom)
  },
})
